
import {
    CalendarOutlined,
    DownloadOutlined
} from "@ant-design/icons-vue";
import {
    base64ToBlob
} from '@/utils/common';

export default {
    name: "AccountCharge",
    components: {
        DownloadOutlined,
        CalendarOutlined
    },
    data() {
        return {
            url: "/user/charge/list",
            columns: [{
                    key: "index",
                    width: 48,
                    customRender: ({
                            index
                        }) =>
                        this.$refs.table.tableIndex + index
                },
                {
                    title: this.$t("user_order.fields.po_number"),
                    dataIndex: this.$setting.env === 'fhjy.lbl.morelink56.com' || this.$setting.env === 'first-star.lbl.morelink56.com'? 'reference2' : "order_po_number",
                    width: 200
                },
                {
                    title: this.$t("user_order.fields.master_tracking_number"),
                    dataIndex: "master_tracking_number",
                    width: 200
                },
                {
                    title: this.$t("account.fields.surcharge_charge_name"),
                    dataIndex: "charge_name",
                    width: 200
                },
                {
                    title: this.$t("share.remark"),
                    dataIndex: "remark"
                },
                {
                    title: this.$t("user_order.fields.charge"),
                    dataIndex: "charge",
                    width: 200,
                    slots: {
                        customRender: "charge"
                    }
                },
                {
                    title: this.$t("share.status"),
                    dataIndex: "status",
                    width: 120,
                    slots: {
                        customRender: "status"
                    }
                },
                {
                    title: this.$t("account.fields.created"),
                    dataIndex: "created",
                    width: 150
                }
            ],
            // 表格搜索条件
            where: {},
            // 表格选中数据
            selection: [],
            // 当前编辑数据
            current: null,
            // 财务类型
            record_type_text: -1,
            // 导出loading
            exportLoading: false
        };
    },
    methods: {
        /* 搜索 */
        reload() {
            if (this.record_type_text != -1) {
                this.where.record_type_text = this.record_type_text;
            } else {
                delete this.where.record_type_text;
            }
            this.selection = [];
            this.$refs.table.reload({
                page: 1
            });
        },
        /*  重置搜索 */
        reset() {
            this.where = {};
            this.$nextTick(() => {
                this.reload();
            });
        },
        /**
         * 导出Excel
         */
        exportExcel() {
            this.exportLoading = true;

            this.$http
                .get(`/user/charge/export`, {
                    params: this.where
                })
                .then(res => {
                    this.exportLoading = false;

                    if (res.data.code === 0) {
                        const blob = base64ToBlob(
                            res.data.data,
                            "application/vnd.ms-excel"
                        );
                        const fileName = `Charges-${new Date().getTime()}.xls`;

                        if ("download" in document.createElement("a")) {
                            // 非IE下载
                            const el = document.createElement("a");

                            el.download = fileName;
                            el.style.display = "none";
                            el.href = URL.createObjectURL(blob);
                            document.body.appendChild(el);

                            el.click();

                            URL.revokeObjectURL(el.href);
                            document.body.removeChild(el);
                        } else {
                            // IE10+下载
                            navigator.msSaveBlob(blob, fileName);
                        }
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
                .catch(e => {
                    this.exportLoading = false;
                    this.$message.error(e.message);
                });
        },
    }
};
